<style scoped lang="scss">
  .system_config {
    .el-form {
      min-width: 250px;
    }
  }
</style>

<template>
  <div class="system_config">
    <el-tabs tab-position="top">
      <el-tab-pane label="系统配置">
        <el-card>
          <div slot="header" class="clearfix">
            <span>系统配置</span>
          </div>
          <el-form
            :model="systemForm.paramsModel"
            :rules="systemForm.paramsRules"
            class="demo-paramsModel"
            label-width="160px"
            ref="sytemParamsForm"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="系统名称" prop="systemName">
                  <el-input placeholder v-model="systemForm.paramsModel.systemName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="默认每页条数" prop="pageSize">
                  <el-select v-model="systemForm.paramsModel.pageSize" placeholder="请选择">
                    <el-option
                      v-for="item in appPageSizes"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="提现手续费" prop="takeServiceFeeRate">
                  <el-input type="number" min="0.01" step="0.01" placeholder v-model="systemForm.paramsModel.takeServiceFeeRate"></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="充电免费时间" prop="staffFreeMinutes">
                  <el-input type="number" min="1" step="1" placeholder v-model="systemForm.paramsModel.staffFreeMinutes">
                    <template slot="append">分钟</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
            <el-col :span="12">
                <el-form-item label="发票收费比例" prop="receiptFeeRate">
                  <el-input type="number" min="0.01" step="0.01" placeholder v-model="systemForm.paramsModel.receiptFeeRate"></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="提现免审核额度" prop="takeNoAuditAmount">
                  <el-input type="number" min="1" step="1" placeholder v-model="systemForm.paramsModel.takeNoAuditAmount"></el-input>

                  <div class="input-tips">请设置大于0</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button
                @click="submitSystemParams"
                type="primary"
              >保存系统设置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="分成配置">
        <el-card>
          <div slot="header" class="clearfix">
            <span>分成配置</span>
          </div>
          <el-form
            :model="distributionSettingsForm.paramsModel"
            :rules="distributionSettingsForm.paramsRules"
            class="demo-paramsModel"
            label-width="180px"
            ref="distributionSettingsForm"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="平台分成比例" prop="platformRate">
                  <el-input type="number" min="0.01" step="0.01" placeholder v-model="distributionSettingsForm.paramsModel.platformRate"></el-input>
                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="总代理分成比例" prop="generalAgentRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.generalAgentRate"
                  ></el-input>
                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="大区经理分成比例" prop="districtManagerRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.districtManagerRate"
                  ></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="投资人比例" prop="agentRate">
                  <el-input type="number" min="0.01" step="0.01" placeholder v-model="distributionSettingsForm.paramsModel.agentRate"></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="上级分成比例" prop="parentAgentRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.parentAgentRate"
                  ></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="点位开发分成比例" prop="pointDevRate">
                  <el-input type="number" min="0.01" step="0.01" placeholder v-model="distributionSettingsForm.paramsModel.pointDevRate"></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="商户可分配比例" prop="merchantMaxRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.merchantMaxRate"
                  ></el-input>

                  <div class="input-tips">商家可分配最大比例，请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="代理分红人最大比例" prop="agentMaxBonusRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.agentMaxBonusRate"
                  ></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="大区经理分红人最大比例" prop="districtManagerAgentMaxBonusRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.districtManagerAgentMaxBonusRate"
                  ></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="总代分红人最大比例" prop="generalAgentMaxBonusRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.generalAgentMaxBonusRate"
                  ></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="丢失设备代理返点比例" prop="lostDeviceAgentRate">
                  <el-input type="number" min="0.01" step="0.01"
                    placeholder
                    v-model="distributionSettingsForm.paramsModel.lostDeviceAgentRate"
                  ></el-input>

                  <div class="input-tips">请设置在0~1区间的值</div>
                </el-form-item>
              </el-col>
              <el-col :span="12"></el-col>
            </el-row>
            <el-form-item>
              <el-button
                @click="submitDistributionSettings"
                type="primary"
              >保存分成配置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="代理配置">
        <el-card class="box-card">
          <el-form
            :model="agentSettingsForm.paramsModel"
            :rules="agentSettingsForm.paramsRules"
            class="demo-paramsModel"
            label-width="170px"
            ref="agentSettingsForm"
          >
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>启用设置</span>
              </div>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="任务奖励" prop="taskRewardEnabled">
                    <el-radio-group v-model="agentSettingsForm.paramsModel.taskRewardEnabled">
                      <el-radio :label="true">开启</el-radio>
                      <el-radio :label="false">关闭</el-radio>
                    </el-radio-group>
                    <div class="input-tips">如果关闭则升级后自己和上级不会获得奖励</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="任务升级功能" prop="taskLevelUpEnabled">
                    <el-radio-group v-model="agentSettingsForm.paramsModel.taskLevelUpEnabled">
                      <el-radio :label="true">开启</el-radio>
                      <el-radio :label="false">关闭</el-radio>
                    </el-radio-group>
                    <div class="input-tips">如果关闭累计任务金额不会升级</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <br />
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>晋级任务额设置</span>
              </div>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="铜牌代理" prop="level1MinTaskAmount">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level1MinTaskAmount"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="银牌代理" prop="level2MinTaskAmount">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level2MinTaskAmount"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="金牌代理" prop="level3MinTaskAmount">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level3MinTaskAmount"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="大区经理" prop="level4MinTaskAmount">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level4MinTaskAmount"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="总代" prop="level5MinTaskAmount">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level5MinTaskAmount"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <br />
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>晋级人奖励设置</span>
              </div>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="铜牌代理" prop="level1Reward">
                    <el-input placeholder v-model="agentSettingsForm.paramsModel.level1Reward"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="银牌代理" prop="level2Reward">
                    <el-input placeholder v-model="agentSettingsForm.paramsModel.level2Reward"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="金牌代理" prop="level3Reward">
                    <el-input placeholder v-model="agentSettingsForm.paramsModel.level3Reward"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="大区经理" prop="level4Reward">
                    <el-input placeholder v-model="agentSettingsForm.paramsModel.level4Reward"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="总代" prop="level5Reward">
                    <el-input placeholder v-model="agentSettingsForm.paramsModel.level5Reward"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <br />
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>晋级人领导人奖励设置</span>
              </div>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="铜牌代理" prop="level1LeaderReward">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level1LeaderReward"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="银牌代理" prop="level2LeaderReward">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level2LeaderReward"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="金牌代理" prop="level3LeaderReward">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level3LeaderReward"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="大区经理" prop="level4LeaderReward">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level4LeaderReward"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="总代" prop="level5LeaderReward">
                    <el-input
                      placeholder
                      v-model="agentSettingsForm.paramsModel.level5LeaderReward"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <br />
            <el-form-item>
              <el-button
                @click="submitAgentSettings"
                type="primary"
              >保存代理配置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="小程序配置">
        <el-card>
          <div slot="header" class="clearfix">
            <span>小程序配置</span>
          </div>
          <el-form
            :model="wechatAppForm.paramsModel"
            :rules="wechatAppForm.paramsRules"
            class="demo-paramsModel"
            label-width="160px"
            ref="wechatAppForm"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="客服电话" prop="ServicePhone">
                  <el-input placeholder v-model="wechatAppForm.paramsModel.ServicePhone" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button
                @click="submitWebchatAppParams"
                type="primary"
              >保存小程序设置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import * as commons from '@/api/common'
  import * as funSystem from '@/api/system/sysConfig'
  export default {
    name: "sysConfig",
    data() {
      return {
        // 新版变量定义
        systemForm: {
          paramsModel: {
            systemName: "",
            pageSize: 0,
            takeServiceFeeRate: 0,
            staffFreeMinutes: 0,
            receiptFeeRate: 0
          },
          paramsRules: {
            systemName: [
              { required: true, message: "请输入系统名", trigger: "blur" }
            ],
            pageSize: [
              { required: true, message: "请选择默认每页条数", trigger: "change" }
            ],
            takeServiceFeeRate: [
              { required: true, message: "请输入提现手续费", trigger: "blur" }
            ],
            staffFreeMinutes: [
              { required: true, message: "充电免费时间", trigger: "blur" }
            ],
            receiptFeeRate: [
              { required: true, message: "发票收费比例", trigger: "blur" }
            ],
            takeNoAuditAmount:[
              { required: true, message: "提现免审核额度", trigger: "blur" }
            ]
          }
        },
        distributionSettingsForm: {
          paramsModel: {
            platformRate: 0,
            generalAgentRate: 0,
            districtManagerRate: 0,
            agentRate: 0,
            parentAgentRate: 0,
            pointDevRate: 0,
            merchantMaxRate: 0,
            agentMaxBonusRate: 0,
            districtManagerAgentMaxBonusRate: 0,
            generalAgentMaxBonusRate: 0,
            lostDeviceAgentRate:0
          },
          paramsRules: {
            platformRate: [
              { required: true, message: "请输入平台分成比例(0~1)", trigger: "blur" }
            ],
            generalAgentRate: [
              {
                required: true,
                message: "请输入总代理分成比例(0~1)",
                trigger: "blur"
              }
            ],
            districtManagerRate: [
              {
                required: true,
                message: "请输入大区经理分成比例(0~1)",
                trigger: "blur"
              }
            ],
            agentRate: [
              { required: true, message: "请输入投资人比例(0~1)", trigger: "blur" }
            ],
            parentAgentRate: [
              { required: true, message: "请输入上级分成比例(0~1)", trigger: "blur" }
            ],
            pointDevRate: [
              {
                required: true,
                message: "请输入点位开发分成比例(0~1)",
                trigger: "blur"
              }
            ],
            merchantMaxRate: [
              {
                required: true,
                message: "请输入商户可分配比例",
                trigger: "blur"
              }
            ]
          }
        },
        agentSettingsForm: {
          paramsModel: {
            taskRewardEnabled: true,
            taskLevelUpEnabled: true,
            level1MinTaskAmount: 0,
            level2MinTaskAmount: 0,
            level3MinTaskAmount: 0,
            level4MinTaskAmount: 0,
            level5MinTaskAmount: 0,
            level1LeaderReward: 0,
            level1Reward: 0,
            level2LeaderReward: 0,
            level2Reward: 0,
            level3LeaderReward: 0,
            level3Reward: 0,
            level4LeaderReward: 0,
            level4Reward: 0,
            level5LeaderReward: 0,
            level5Reward: 0
          },
          paramsRules: {
            level1MinTaskAmount: [
              { required: true, message: "请输入铜牌升级最低金额", trigger: "blur" }
            ],
            level2MinTaskAmount: [
              { required: true, message: "请输入银牌升级最低金额", trigger: "blur" }
            ],
            level3MinTaskAmount: [
              { required: true, message: "请输入金牌升级最低金额", trigger: "blur" }
            ],
            level4MinTaskAmount: [
              {
                required: true,
                message: "请输入大区经理升级最低金额",
                trigger: "blur"
              }
            ],
            level5MinTaskAmount: [
              { required: true, message: "请输入总代升级最低金额", trigger: "blur" }
            ],
            level1LeaderReward: [
              { required: true, message: "请输入铜牌领导奖励", trigger: "blur" }
            ],
            level1Reward: [
              { required: true, message: "请输入铜牌晋级人奖励", trigger: "blur" }
            ],
            level2LeaderReward: [
              { required: true, message: "请输入银牌领导奖励", trigger: "blur" }
            ],
            level2Reward: [
              { required: true, message: "请输入银牌晋级人奖励", trigger: "blur" }
            ],
            level3LeaderReward: [
              { required: true, message: "请输入金牌领导奖励", trigger: "blur" }
            ],
            level3Reward: [
              { required: true, message: "请输入金牌晋级人奖励", trigger: "blur" }
            ],
            level4LeaderReward: [
              { required: true, message: "请输入大区经理领导奖励", trigger: "blur" }
            ],
            level4Reward: [
              { required: true, message: "请输入大区经理晋级人奖励", trigger: "blur" }
            ],
            level5LeaderReward: [
              { required: true, message: "请输入升总代领导奖励", trigger: "blur" }
            ],
            level5Reward: [
              { required: true, message: "请输入升总代晋级人奖励", trigger: "blur" }
            ]
          }
        },
        wechatAppForm: {
          paramsModel: {
            ServicePhone: ""
          },
          paramsRules: {
            ServicePhone: [
              { required: true, message: "请输入客服电话", trigger: "blur" }
            ]
          }
        }
      }
    },
    mounted() {
      this.initData()
    },
    methods: {
      async initData() {
        window.$common.fullLoading()
        await this.getConfigData()
        await this.getDistributionData()
        await this.getAgentData()
        await this.getWechatAppData()
        window.$common.closeFullLoading()
      },
      // 获取小程序配置
      async getWechatAppData() {
        await funSystem.GetClientConfig({ name: "WechatAppConfigs" }).then(res => {
          this.wechatAppForm.paramsModel = res
        })
      },
      submitWebchatAppParams() {
        this.$refs.wechatAppForm.validate(async (valid) => {
            if (valid) {
              window.$common.fullLoading()
              let params = {
                name: "WechatAppConfigs",
                value: {
                  ServicePhone: this.wechatAppForm.paramsModel.ServicePhone
                }
              }
              funSystem.ChangeClientConfig(params).then(() => {
                this.alert("小程序配置保存成功!").then(() => {
                  window.$common.closeFullLoading()
                })
              })
              window.$common.closeFullLoading()
            }
          }
        );
      },
      // 获取系统配置
      async getConfigData() {
        await commons.getSystemSettings().then(res => {
          this.configData = { ...res }
          this.systemForm.paramsModel = res
        })
      },
      submitSystemParams() {
        this.$refs.sytemParamsForm.validate(async (valid) => {
          if (valid) {
            window.$common.fullLoading()
            commons.updateSystemSettings(this.systemForm.paramsModel).then(() => {
              this.alert("系统配置保存成功!").then(() => {
                window.$common.closeFullLoading()
                // 修改后重新保存storege
                this.setSystemConfig()
              })
            }).catch(() => {
              window.$common.closeFullLoading()
            })
          }
        });
      },
      // 获取分成配置
      async getDistributionData() {
        await funSystem.getDistributionSettings().then(res => {
          this.distributionSettingsForm.paramsModel = res
        })
      },
      submitDistributionSettings() {
        this.$refs.distributionSettingsForm.validate(async (valid) => {
            if (valid) {
              window.$common.fullLoading()
              funSystem.ChangeDistributionSettings(this.distributionSettingsForm.paramsModel).then(() => {
                this.alert("分成配置保存成功!").then(() => {
                  window.$common.closeFullLoading()
                })
              }).catch(() => {
                window.$common.closeFullLoading()
              })
            }
          }
        );
      },
      // 获取代理配置
      async getAgentData() {
        await funSystem.getAgentSettings().then(res => {
          this.agentSettingsForm.paramsModel = res
        })
      },
      submitAgentSettings() {
        this.$refs.agentSettingsForm.validate(async (valid) => {
          if (valid) {
            window.$common.fullLoading()
            funSystem.ChangeAgentSettings(this.agentSettingsForm.paramsModel).then(() => {
              this.alert("代理配置保存成功!").then(() => {
                window.$common.closeFullLoading()
              })
            }).catch(() => {
              window.$common.closeFullLoading()
            })
          }
        });
      }
    }
  }
</script>